@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';


html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex-grow: 1;
}

.footer {
  width: 100%;
  background-color: #212529;
  text-align: center;
  padding-bottom: 2rem;
  color: white;
  padding-top: 2rem;
}

.layout {
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(37, 123, 250, 1) 98%);
}





.cab-card {
  border: 1px solid gray;
  border-radius: .5rem;
  width: 50vw;
  height: 15rem;
  margin: auto;
  background-color: white;

}



@media screen and (max-width: 1511px) and (min-width: 1150px) {
  .cab-card h3 {
    font-size: 1.2rem;
  }

  .cab-card .head {
    margin-top: .8rem;

  }

  .cab-card h4 {
    font-size: .9rem;
  }

  .cab-card h6 {
    font-size: .8rem;
  }

  .cab-card span,
  li {
    font-size: .6rem;
  }

  .cab-card p {
    font-size: .6rem;
  }


  .cab-card .button {
    font-size: .6rem;

  }

  .cab-card img {
    width: 70px;
  }
}

@media screen and (max-width: 1150px) and (min-width: 900px) {
  .cab-card h3 {
    font-size: 1rem;
  }

  .cab-card .head {
    margin-top: .8rem;

  }

  .cab-card h4 {
    font-size: .8rem;
  }

  .cab-card h6 {
    font-size: .6rem;
  }

  .cab-card span,
  li {
    font-size: .5rem;
  }

  .cab-card p {
    font-size: .5rem;
  }

  .cab-card .button {
    font-size: .5rem;
    padding: 3px;

  }

  .cab-card img {
    width: 55px;
  }

  .cab-card {
    height: 11rem;
  }
}

@media screen and (max-width: 900px) and (min-width: 800px) {
  .cab-card h3 {
    font-size: .8rem;
  }

  .cab-card .head {
    margin-top: .8rem;

  }

  .cab-card h4 {
    font-size: .7rem;
  }

  .cab-card h6 {
    font-size: .55rem;
  }

  .cab-card .icon {
    font-size: .8rem;
  }

  .cab-card span,
  li {
    font-size: .4rem;
  }

  .cab-card p {
    font-size: .4rem;
  }




  .cab-card .button {
    font-size: .4rem;
    padding: 3px;

  }

  .cab-card img {
    width: 50px;
  }

  .cab-card {
    height: 10rem;
    width: 70vw;
  }
}

@media screen and (max-width: 800px) and (min-width: 450px) {
  .cab-card h3 {
    font-size: .7rem;
  }

  .cab-card .head {
    margin-top: .8rem;

  }

  .cab-card h4 {
    font-size: .6rem;
  }

  .cab-card h6 {
    font-size: .5rem;
  }

  .cab-card .icon {
    font-size: .7rem;
  }

  .cab-card span {
    font-size: .35rem;
  }

  .cab-card p {
    font-size: .4rem;
  }


  .cab-card li {
    font-size: .4rem;
  }



  .cab-card .button {
    font-size: .35rem;
    padding: 2.5px;

  }

  .cab-card img {
    width: 45px;
  }

  .cab-card {
    height: 10rem;
    width: 80vw;
  }
}

@media screen and (max-width: 450px) and (min-width: 150px) {
  .cab-card h3 {
    font-size: .7rem;
  }

  .cab-card .head {
    margin-top: .8rem;

  }

  .cab-card h4 {
    font-size: .5rem;
  }

  .cab-card h6 {
    font-size: .45rem;
  }

  .cab-card .icon {
    font-size: .7rem;
  }

  .cab-card span {
    font-size: .4rem;
  }

  .cab-card p {
    font-size: .4rem;
  }

  .cab-card li {
    font-size: .4rem;
  }



  .cab-card .button {
    font-size: .45rem;
    padding: 2.5px;

  }

  .cab-card .price {
    margin-bottom: 0;
  }

  .cab-card img {
    width: 45px;
  }

  .cab-card {
    height: 10rem;
    width: 80vw;
  }
}